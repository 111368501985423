<template>
  <PublicLayout>
    <div
      class="container"
      :class="device + '-container'"
    >
      <img
        v-if="!mq.phone"
        src="@/assets/img_intro.png"
        class="img-intro"
        alt=""
      >

      <h1 v-t="'ContactUs.title'" />

      <form
        v-if="!isMessageSent"
        class="section"
        action=""
        @submit.prevent="submit"
      >
        <!-- First name -->
        <div class="field">
          <label v-t="'ContactUs.firstName'" />
          <Input
            id="first-name-input"
            v-model="firstName"
            :placeholder="$t('ContactUs.firstNamePlaceHolder')"
            @blur="v$.firstName.$touch()"
          />
          <ErrorMessage :error-message="formErrorList.firstName" />
        </div>

        <!-- Last name -->
        <div class="field">
          <label v-t="'ContactUs.lastName'" />
          <Input
            v-model="lastName"
            :placeholder="$t('ContactUs.lastNamePlaceHolder')"
            @blur="v$.lastName.$touch()"
          />
          <ErrorMessage :error-message="formErrorList.lastName" />
        </div>

        <!-- Email -->
        <div class="field">
          <label v-t="'ContactUs.email'" />
          <Input
            v-model="email"
            :placeholder="$t('ContactUs.emailPlaceHolder')"
            @blur="v$.email.$touch()"
          />
          <ErrorMessage :error-message="formErrorList.email" />
        </div>

        <!-- Subject -->
        <div class="field">
          <label v-t="'ContactUs.subject'" />
          <Dropdown
            v-model="selectedSubject"
            :list="subjectList"
            :sort="false"
            list-max-width="300px"
            class="group-selection"
          />
        </div>

        <!-- Message -->
        <div class="field">
          <label
            v-t="'ContactUs.message'"
            for="textarea"
          />
          <textarea
            id="textarea"
            v-model="message"
            :placeholder="$t('ContactUs.messagePlaceHolder')"
            @blur="v$.message.$touch()"
          />
          <ErrorMessage :error-message="formErrorList.message" />
        </div>

        <!-- TODO -->
        <div class="captcha" />

        <div
          v-t="'ContactUs.legend'"
          class="legend"
        />

        <input
          type="submit"
          class="submit-button"
          :value="$t('ContactUs.submit')"
        >
      </form>

      <section
        v-else
        class="section"
      >
        <p v-t="'router.views.ContactUs.successMessage'" />

        <Button
          class="home-button"
          @click="redirectHome"
        >
          {{ $t('router.views.ContactUs.backToHome') }}
        </Button>
      </section>
      <br>
    </div>
  </PublicLayout>
</template>

<script>
import messageService from '@/api/messaging/message.service'
import PublicLayout from '@/router/layouts/PublicLayout.vue'
import ErrorMessage from '@components/Base/ErrorMessage'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import Button from '@components/Base/Button'

export default {
  name: 'ContactUs',
  components: {
    Button,
    PublicLayout,
    ErrorMessage
  },
  inject: ['mq'],
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    email: {
      required,
      email
    },
    message: {
      required
    }
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      error: '',
      message: '',
      selectedSubject: undefined,
      subjectList: [
        this.$t('ContactUs.subjectList.informationRequest'),
        this.$t('ContactUs.subjectList.improvementProposal'),
        this.$t('ContactUs.subjectList.bug'),
        this.$t('ContactUs.subjectList.other')
      ],
      isMessageSent: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    formErrorList () {
      return {
        firstName: (this.v$.firstName.$invalid && this.v$.firstName.$dirty)
          ? (this.v$.firstName.required.$invalid
            ? this.$t('router.views.ContactUs.required')
            : '')
          : '',
        lastName: (this.v$.lastName.$invalid && this.v$.lastName.$dirty)
          ? (this.v$.lastName.required.$invalid
            ? this.$t('router.views.ContactUs.required')
            : '')
          : '',
        email: (this.v$.email.$invalid && this.v$.email.$dirty)
          ? (this.v$.email.required.$invalid
            ? this.$t('router.views.ContactUs.required')
            : (this.v$.email.email.$invalid
              ? this.$t('router.views.ContactUs.invalidEmail')
              : ''
            ))
          : '',
        message: (this.v$.message.$invalid && this.v$.message.$dirty)
          ? (this.v$.message.required.$invalid
            ? this.$t('router.views.ContactUs.required')
            : '')
          : ''
      }
    }
  },
  mounted () {
    const input = document.getElementById('first-name-input')
    input.focus()
    input.select()
  },
  methods: {
    submit () {
      if (this.v$.$invalid) { // form checking
        this.v$.$touch()
      } else {
        messageService.contactSupport(this.email, this.selectedSubject, this.firstName, this.lastName, this.message).then((data) => {
          if (data.success) {
            this.cleanFields()
            this.isMessageSent = true
          } else {
            this.$store.dispatch('popups/pushPopup', { message: this.$t('router.views.ContactUs.failStatus') })
          }
        })
      }
    },
    cleanFields () {
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.message = ''
      this.selectedSubject = this.subjectList[0]
      this.v$.$reset() // Reset the isDirty property to have a clean form
    },
    redirectHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  padding: 60px 15% 0 15%;
  min-height: calc(100vh - (#{$public-desktop-banner-height} + #{$public-desktop-footer-height}));
  overflow-y: auto;
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-light-text;

  .img-intro {
    position: absolute;
    top: -25px;
    left: -150px;
    height: 800px;
    z-index: 0;
  }

  h1 {
    position: relative;
    font-size: 40px;
    margin-bottom: 36px;
  }

  .section {
    position: relative;
    margin-top: 30px;
    margin-bottom: 80px;
    padding: 20px 10%;
    width: 100%;
    max-width: 832px;
    background-color: $dark-white-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    color: $color-cadyco-dark-text;

    .field {
      width: 100%;

      label {
        display:block;
        margin-top: 1.33em;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 21px;
      }

      textarea {
        resize: none;
        width: 100%;
        min-height: 100px;
      }
    }

    .legend {
      margin-top: 15px;
      width: 100%;
      color: $color-cadyco-dark-text;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
    }

    .submit-button {
      margin-top: 2em;
      margin-bottom: 1em;
      height: 48px;
      border-radius: 6px;
      background-color: $color-cadyco-dark-button;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-cadyco-light-text;
    }

    p {
      font-size: 1.125em;
      margin: 30px 0 50px 0;
    }

    .home-button {
      height: 49px;
      width: 250px;
      font-weight: bold;
      background-color: $color-cadyco-dark-text;
      color: $color-cadyco-light-text;
    }
  }
}

.phone-container {
  padding: 30px 5% 0 5%;

  h1{
    font-size: 2em;
    line-height: 30px;
    margin-bottom: 36px;
    position: relative;
  }

  p {
    width: 100%;
    font-size: 1.125em;
    margin-top: 0;
    line-height: 22px;
    position: relative;
    text-align: left;
  }

  .section {
    margin-top: 0;
  }
}

</style>
